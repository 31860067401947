import React from 'react'
import Sitemap from "./Footer/Sitemap.js";
import Contact from "./Footer/Contact.js";

function Footer() {
  return (
    <div className="text-gray-400 bg-gray-200 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
      <div class="container flex flex-wrap justify-between items-center mx-auto">
        <div style={{width: "100%", display: "flex", gap: "16px", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
          <div className='sitemap'>
            <Sitemap />
          </div>
          <div className='contact'>
            <Contact />
          </div>
          <div>

          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Footer;