import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route,} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Subsites

import Finder from ".//routes/produkte/finder";
import Produkte from ".//routes/produkte/table";


import Wir from ".//routes/wir";

import Register from ".//routes/register";
import Login from ".//routes/login";

//Dashboard
import Dashboard from ".//routes/dashboard/dashboard";
import Settings from ".//routes/dashboard/settings";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="finder" element={<Finder />} />
      <Route path="produkte" element={<Produkte />} />
      <Route path="wir" element={<Wir />} />

      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
