import React from 'react'

function Sitemap() {
  return (
    <div>
        Sitemap
    </div>
  )
}

export default Sitemap