import React from 'react'
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

function Wir() {
  return (
    <main>
      <Navbar />

      <div>Wir</div>

      <Footer />
    </main>
  )
}

export default Wir