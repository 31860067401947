import React from 'react'
import Navbar from "../../components/Navbar.js";
import Footer from "../../components/Footer.js";
import ProductComponent from './ProductComponent.js';

function table() {




  return (
    <main>
            <Navbar />

            <div style={{paddingLeft: "15%",paddingRight: "15%"}}>
                <div className="text-center">
                    <h2 className="text-xl font-semibold">Unsere Produkte</h2>
                    <p>Hier ist eine Auflistung all unserer Produkte:</p>
                </div>

                <div style={{paddingTop: "75px"}} id="hypixel">
                    <img style={{display:"inline"}} src="../images/Hypixel-1970x1970.png" alt="Hypixel-Logo" width={"50px"} />
                    <h3 className="font-semibold text-xl" style={{display:"inline"}}>Hypixel.net</h3>
                    <div style={{width: "100%", display: "flex", gap: "16px", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                        <ProductComponent type={"hypixel"} />
                    </div>
                </div>

                <div style={{paddingTop: "75px"}} id="mixelpixel">
                    <img style={{display:"inline"}} src="../images/MixelPixel-1970x1970.png" alt="MixelPixel-Logo" width={"50px"} />
                    <h3 className="font-semibold text-xl" style={{display:"inline"}}>MixelPixel</h3>
                    <div style={{width: "100%", display: "flex", gap: "16px", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                        <ProductComponent type={"mixelpixel"} />
                    </div>
                </div>
            </div>

            <Footer />
        </main>
  )
}

export default table