import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <main>
      <Navbar />

      <Footer />
    </main>
  );
}

export default App;
