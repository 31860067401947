import React from 'react'
const products = ("./produkte.json");

function ProductComponent(type) {

    let productsParsed = JSON.parse(products)

    let found = productsParsed.filter(products => products.for = type);
    


    while(type == "hypixel") {
        return (
            <div style={{width:"400px", height: "500px"}}>
                <img src={"https://safegamez.net/images/produkte/" + found.img} width={"400px"} height={"400px"} alt={"Produktbild"} />
                <h3 id='productName' className="font-semibold text-lg">${found.name}</h3>
                <p id='productPrice' className='text-right diplay-inline'>${found.name}</p>
                <p id='productDesc' className="text-base">${found.name}</p>
            </div>
          )

    } 
    while(type = "mixelpixel") {

        return (
            <div style={{width:"400px", height: "500px"}}>
                <img src={"https://safegamez.net/images/produkte/" + found.img} width={"400px"} height={"400px"} alt={"Produktbild"} />
                <h3 id='productName' className="font-semibold text-lg">${found.name}</h3>
                <p id='productPrice' className='text-right diplay-inline'>${found.name}</p>
                <p id='productDesc' className="text-base">${found.name}</p>
            </div>
          )
    }
}

export default ProductComponent;