import React from 'react'
import Navbar from "../../components/Navbar.js";
import Footer from "../../components/Footer.js";


function Finder() {
    return (
        <main>
            <Navbar />

            <div id='content'>
                <div className="self-center text-center py-3">
                    <h1 className="text-xl font-semibold">Wähle deinen Server aus:</h1>
                </div>

                <div style={{width: "100%", display: "flex", gap: "16px", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                    <a href='./produkte/#hypixel'>
                        <div className="rounded md:hover:bg-gray-200">
                            <img src="..//images/Hypixel-1970x1970.png" width={"500px"} height={"500px"} alt="Hypixel Logo" />
                            <p style={{position: "relative", top: "-55px"}} className="text-center">Hypixel</p>
                        </div>
                    </a>

                    <a href="./produkte/#mixelpixel">
                        <div className="rounded md:hover:bg-gray-200">
                            <img src="..//images/MixelPixel-1970x1970.png" width={"500px"} height={"500px"} alt="MixelPixel Logo" />
                            <p style={{position: "relative", top: "-55px"}} className="text-center">MixelPixel</p>
                        </div>
                    </a>
                </div>
            </div>

            <Footer />
        </main>
    )
}

export default Finder;